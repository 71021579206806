import * as Sentry from '@sentry/nextjs'
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect,
} from 'firebase/auth'
import React, { useCallback, useEffect, useState } from 'react'

import GoogleLoginButton from './GoogleLoginButton'

const FirebaseAuth = () => {
  const [error, setError] = useState('')

  useEffect(() => {
    const auth = getAuth()
    getRedirectResult(auth).catch(error => {
      setError(error.message || 'An error occured')
      Sentry.captureException(error)
    })
  }, [])

  const handleGoogleClick = useCallback(() => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      // Forces account selection even when one account
      // is available.
      prompt: 'select_account',
    })

    signInWithRedirect(getAuth(), provider)
  }, [])

  return (
    <div>
      {error && (
        <div className='flex mt-6 justify-center'>
          <p className='text-error-600'>{error}</p>
        </div>
      )}
      <GoogleLoginButton onClick={handleGoogleClick} />
    </div>
  )
}

export default FirebaseAuth
